import { createBrowserRouter } from 'react-router-dom';
import { BaseLayout } from './layout';
import { Home } from './containers/home';

export const router: ReturnType<typeof createBrowserRouter> =
  createBrowserRouter([
    // Tempory disabled
    {
      path: '/',
      Component: BaseLayout,
      children: [{ path: '/', Component: Home }],
    },
  ]);
