import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import logo from '@/assets/logo.svg';

export const AppHeader = (): ReactElement => {
  const isSmBreakpoint = useMediaQuery({ query: '(min-width: 480px)' });

  return (
    <nav className="mx-auto flex h-16 max-w-7xl justify-between bg-transparent px-6 lg:px-8">
      <div className="z-10 flex flex-1 flex-shrink-0 items-center">
        <img
          src={logo}
          alt="Logo"
          width={isSmBreakpoint ? '48px' : '32px'}
          height={!isSmBreakpoint ? '26px' : '16px'}
        />
      </div>
    </nav>
  );
};
