import { ReactElement, useState } from 'react';
import { BackdropBg, Button, Highlight, Input } from '@/components';
import { HowWorks } from './components/HowWorks';
import { tmaApi } from '@/instances';
import { IconCircleCheck } from '@tabler/icons-react';
import { toast } from 'sonner';

export const Home = (): ReactElement => {
  const [email, setEmail] = useState('');
  const [telegram, setTelegram] = useState('');
  const [isJoined, setIsJoined] = useState(false);

  const handleJoin = async () => {
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email');
      return;
    }

    if (!telegram) {
      toast.error('Please enter your telegram');
      return;
    }

    await tmaApi.v1.waitlistJoin({
      email,
      telegram,
    });

    setIsJoined(true);
    setEmail('');
    setTelegram('');
  };

  const validateEmail = (email: string) => {
    // Simple regex for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <div className="relative isolate pt-14">
      <div className="py-24 sm:py-32 lg:pb-40">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Mozi {'  '}
              <Highlight>My Yields </Highlight>
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Mozi segregates user's yield from any LSTs and utilizing the yield
              in the protocol to earn higher yields up to 100x.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://discord.gg/6gWK6TKcRk"
                target="_blank"
                className="animate-scale rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Join Discord <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          {/* <div className="mt-16 flow-root sm:mt-24">
            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                alt="App screenshot"
                src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                width={2432}
                height={1442}
                className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
              />
            </div>
          </div> */}
        </div>
      </div>
      <BackdropBg />
      <HowWorks />
      <div className="mx-auto flex max-w-2xl flex-col gap-2 px-6">
        <p className="pb-4 text-center text-3xl font-bold">
          🎉 Join Our Waitlist for Beta Test! 📝✨
        </p>

        {!isJoined && (
          <>
            <p className="pt-2 font-semibold"> Email</p>
            <Input
              value={email}
              placeholder="example@email.com"
              onChange={handleEmailChange}
            />

            <p className="pt-2 font-semibold"> Telegram</p>
            <Input
              value={telegram}
              placeholder="@example_xyz"
              onChange={(e) => setTelegram(e.target.value)}
            />

            <Button
              className="mt-6 w-full rounded-full font-mono uppercase"
              onClick={() => handleJoin()}
            >
              Join
            </Button>
          </>
        )}

        {isJoined && (
          <>
            <IconCircleCheck
              size={48}
              className="mx-auto mt-2 text-green-500"
            />
            <p className="text-center font-semibold">
              🙏 Thank you for joining our waitlist! 🎉💌
            </p>
          </>
        )}
      </div>
    </div>
  );
};
