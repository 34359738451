/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface EnterReqEntry {
  /** MongoDB ObjectID represented as string */
  wheel_id: string;
  /** Decimal128 represented as string */
  amount: string;
  currency: V1WalletCurrency;
}

export interface GetCurrentMiningReplyMiningInfo {
  amount: string;
  currency: V1WalletCurrency;
  /** @format date-time */
  completed_at: string;
  /** @format int64 */
  duration: string;
}

export interface GetFriendsReplyFriend {
  profile: V1UserProfile;
}

export interface GetQuestReplyQuestSection {
  name: string;
  /** @format int64 */
  count: string;
  quests: V1QuestInfo[];
}

export interface GetWheelGameReplyEntryInfo {
  /** MongoDB ObjectID represented as string */
  color: string;
  /** Decimal128 represented as string */
  amount: string;
  /** name of the winner */
  name: string;
}

export interface GetWheelGameReplyMyEntry {
  /** MongoDB ObjectID represented as string */
  color: string;
  /** Decimal128 represented as string */
  amount: string;
}

export interface WheelGameInfoWinner {
  /** MongoDB ObjectID represented as string */
  color: string;
  /** Decimal128 represented as string */
  amount: string;
  /** name of the winner */
  name: string;
}

export interface ProtobufAny {
  '@type'?: string;
  [key: string]: any;
}

export interface RpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

export interface V1CancelReply {
  game_info: V1WheelGameInfo;
}

export interface V1CancelReq {
  wheel_ids: string[];
}

export interface V1CreateWheelGameReply {
  wheel_id: string;
  code?: string;
}

export interface V1CreateWheelGameReq {
  /** @format int64 */
  max_player: string;
  max_entry?: string;
  /** @format int64 */
  duration: string;
  currency: V1WalletCurrency;
  is_private: boolean;
}

export interface V1Currency {
  currency: V1WalletCurrency;
  amount: string;
}

export interface V1EnterReq {
  enter_infos: EnterReqEntry[];
}

export type V1GetActivityReply = object;

export interface V1GetBalanceReply {
  balance: string;
}

export interface V1GetCurrentMiningReply {
  info?: GetCurrentMiningReplyMiningInfo;
}

export interface V1GetFriendsReply {
  friends: GetFriendsReplyFriend[];
  referral_rewards: V1Currency[];
}

export interface V1GetProfileReply {
  profile: V1UserProfile;
  /** @format int64 */
  log_in_streak: string;
}

export interface V1GetQuestReply {
  banner_quests: V1QuestInfo[];
  quest_sections: GetQuestReplyQuestSection[];
}

export interface V1GetWheelGameReply {
  info: V1WheelGameInfo;
  deposits: GetWheelGameReplyEntryInfo[];
  my_entry?: GetWheelGameReplyMyEntry;
}

export interface V1GetWheelGamesReply {
  info: V1WheelGameInfo[];
}

export interface V1JoinReply {
  queue_id: string;
}

export interface V1JoinReq {
  email: string;
  telegram: string;
}

export interface V1LogInReply {
  profile: V1UserProfile;
  /** @format int64 */
  log_in_streak: string;
  daily_login_reward: V1Currency;
}

export interface V1LogInReq {
  invitation_code?: string;
}

export type V1MineReq = object;

export interface V1QuestInfo {
  id: string;
  title: string;
  amount: string;
  currency: V1WalletCurrency;
  url: string;
  icon_url?: string;
  image_url?: string;
  is_completed: boolean;
}

export interface V1UserProfile {
  first_name: string;
  last_name: string;
  username: string;
  langauge_code: string;
  photo_url: string;
  ref_code: string;
}

export enum V1WalletCurrency {
  WALLET_CURRENCY_TICKET = 'WALLET_CURRENCY_TICKET',
  WALLET_CURRENCY_USD = 'WALLET_CURRENCY_USD',
}

export interface V1WheelGameInfo {
  /** MongoDB ObjectID represented as string */
  wheel_id: string;
  /** @format int64 */
  round: string;
  /** @format int64 */
  max_player: string;
  currency: V1WalletCurrency;
  /** Decimal128 represented as string */
  prize_pool: string;
  /** @format date-time */
  start_time: string;
  /** @format date-time */
  cutoff_time: string;
  winner?: WheelGameInfoWinner;
  /** state => status */
  status: V1WheelGameStatus;
  next_game_id?: string;
  /** Decimal128 represented as string */
  min_entry: string;
  /** Decimal128 represented as string */
  max_entry: string;
  /**
   * TODO: add secret hash
   * @format int64
   */
  player_count: string;
}

export enum V1WheelGameStatus {
  WHEEL_GAME_STATUS_OPEN = 'WHEEL_GAME_STATUS_OPEN',
  WHEEL_GAME_STATUS_DRAWING = 'WHEEL_GAME_STATUS_DRAWING',
  WHEEL_GAME_STATUS_DRAWN = 'WHEEL_GAME_STATUS_DRAWN',
  WHEEL_GAME_STATUS_CANCELED = 'WHEEL_GAME_STATUS_CANCELED',
}

export interface V1VerifyQuestReq {
  quest_id: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title v1/mining.proto
 * @version version not set
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * No description
     *
     * @tags Mining
     * @name MiningGetCurrentMining
     * @request GET:/v1/mining
     */
    miningGetCurrentMining: (params: RequestParams = {}) =>
      this.request<V1GetCurrentMiningReply, RpcStatus>({
        path: `/v1/mining`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mining
     * @name MiningMine
     * @request POST:/v1/mining
     */
    miningMine: (body: V1MineReq, params: RequestParams = {}) =>
      this.request<V1GetActivityReply, RpcStatus>({
        path: `/v1/mining`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quest
     * @name QuestGetQuest
     * @request GET:/v1/quest
     */
    questGetQuest: (params: RequestParams = {}) =>
      this.request<V1GetQuestReply, RpcStatus>({
        path: `/v1/quest`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quest
     * @name QuestVerifyQuest
     * @request POST:/v1/quest
     */
    questVerifyQuest: (body: V1VerifyQuestReq, params: RequestParams = {}) =>
      this.request<V1GetActivityReply, RpcStatus>({
        path: `/v1/quest`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetProfile
     * @request GET:/v1/user
     */
    userGetProfile: (params: RequestParams = {}) =>
      this.request<V1GetProfileReply, RpcStatus>({
        path: `/v1/user`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
 * No description
 *
 * @tags User
 * @name UserLogIn
 * @summary create or update user information
good to call everytime user login
 * @request POST:/v1/user
 */
    userLogIn: (body: V1LogInReq, params: RequestParams = {}) =>
      this.request<V1LogInReply, RpcStatus>({
        path: `/v1/user`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetActivity
     * @request GET:/v1/user/activity
     */
    userGetActivity: (params: RequestParams = {}) =>
      this.request<V1GetActivityReply, RpcStatus>({
        path: `/v1/user/activity`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetBalance
     * @request GET:/v1/user/balance
     */
    userGetBalance: (params: RequestParams = {}) =>
      this.request<V1GetBalanceReply, RpcStatus>({
        path: `/v1/user/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetFriends
     * @request GET:/v1/user/friends
     */
    userGetFriends: (params: RequestParams = {}) =>
      this.request<V1GetFriendsReply, RpcStatus>({
        path: `/v1/user/friends`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Waitlist
     * @name WaitlistJoin
     * @request POST:/v1/waitlist
     */
    waitlistJoin: (body: V1JoinReq, params: RequestParams = {}) =>
      this.request<V1JoinReply, RpcStatus>({
        path: `/v1/waitlist`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags WheelGame
     * @name WheelGameGetWheelGame
     * @request GET:/v1/wheel
     */
    wheelGameGetWheelGame: (
      query?: {
        wheel_id?: string;
        code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1GetWheelGameReply, RpcStatus>({
        path: `/v1/wheel`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags WheelGame
     * @name WheelGameCreateWheelGame
     * @request POST:/v1/wheel
     */
    wheelGameCreateWheelGame: (body: V1CreateWheelGameReq, params: RequestParams = {}) =>
      this.request<V1CreateWheelGameReply, RpcStatus>({
        path: `/v1/wheel`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags WheelGame
     * @name WheelGameCancel
     * @request POST:/v1/wheel/cancels
     */
    wheelGameCancel: (body: V1CancelReq, params: RequestParams = {}) =>
      this.request<V1CancelReply, RpcStatus>({
        path: `/v1/wheel/cancels`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags WheelGame
     * @name WheelGameEnter
     * @request POST:/v1/wheel/enters
     */
    wheelGameEnter: (body: V1EnterReq, params: RequestParams = {}) =>
      this.request<V1GetActivityReply, RpcStatus>({
        path: `/v1/wheel/enters`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags WheelGame
     * @name WheelGameGetWheelGames
     * @request GET:/v1/wheels
     */
    wheelGameGetWheelGames: (params: RequestParams = {}) =>
      this.request<V1GetWheelGamesReply, RpcStatus>({
        path: `/v1/wheels`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
