import { ReactElement } from 'react';
import {
  ArrowPathIcon,
  CircleStackIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';

export const HowWorks = (): ReactElement => {
  const features = [
    {
      name: '1. Stake LST',
      description:
        'Stake native token with any providers like apriori, kintsu, magma, etc. to get LSTs.',
      icon: CircleStackIcon,
    },
    {
      name: '2. Deposit LSTs',
      description: 'Deposit into Mozi Vaults to earn yields for every Epoch.',
      icon: LockClosedIcon,
    },
    {
      name: '3. Play Games',
      description:
        'Use the yield to play games and earn higher yields up to 100x.',
      icon: ArrowPathIcon,
    },
  ];

  return (
    <div className="py-24 sm:py-32 lg:pb-40">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Stake and Boots
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Maximize Your Yield Upto 100x
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Deposit any LSTs into Mozi Vault, every time yield is generated.
            Users can use the yield to play any games and earn higher yields up
            to 100x.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon
                    aria-hidden="true"
                    className="h-5 w-5 flex-none text-indigo-600"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
