import { Api } from '@/apis/tma.swagger';
import axios from 'axios';

export const apiAxios = axios.create({
  baseURL: 'https://tma.mozi.finance/',
});

const tmaApi = new Api();
tmaApi.instance = apiAxios;

export { tmaApi };
